var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3",attrs:{"flat":""}},[_c('p',{staticClass:"clav-content-title-1"},[_vm._v("Utilizadores")]),_c('p',{staticClass:"clav-content-text px-8 py-2"},[_vm._v(" A partir desta página será possível gerir todos os utilizadores presentes na plataforma, sendo possível, dependendo do nível de acesso atribuído, consultar todos os utilizadores registados na plataforma e seus detalhes, registar utilizadores para uma dada entidade, analisar acessos à plataforma, etc. ")]),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4","justify":"center","align":"center"}},[(
          this.$userLevel() > 0 &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
          'px-8': _vm.$vuetify.breakpoint.lgAndUp,
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
        },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/users/registoParaEntidade')}}},[_c('unicon',{attrs:{"name":"users-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.451","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Registar Utilizadores")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4","justify":"center","align":"center"}},[_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
          'px-8': _vm.$vuetify.breakpoint.lgAndUp,
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
        },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/users/listagem')}}},[_c('unicon',{attrs:{"name":"consultar-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.697","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Consultar Utilizadores")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","justify":"center","align":"center"}},[(
          this.$userLevel() > 0 &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
          'px-8': _vm.$vuetify.breakpoint.lgAndUp,
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
        },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/users/registoAcesso')}}},[_c('unicon',{attrs:{"name":"analise-icon","width":"20","height":"20","viewBox":"0 0 20.71 15.575","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Registo de Acessos")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }